<template>
  <div class="latest">
    <div class="wrap">
      <div
        class="donation"
        v-for="(donation, index) in latestDonationsSorted"
        :key="`${index}-${currentTime}`"
      >
        <div class="time-amount">
          <span class="time">{{ timeAgo(donation.timestamp) }}</span>
          <span class="amount"
            >{{ roundAmount(donation.amount[donation.asset]) }}&nbsp;{{donation.asset}}</span
          >
        </div>
        <div class="from-address">
          <span class="from">from</span>
          <span class="address" @click="copyToClipboard(donation.from)">{{
            senderAddress(donation.from)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import TimeAgo from "javascript-time-ago"
import TimeAgoLocaleEn from "javascript-time-ago/locale/en.json"

export default {
  name: "LatestDonations",
  mixins: [mixinGlobal],
  props: {
    latestDonations: {
      type: Array,
    },
    client: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      truncatedAddressBreakpoint: 535,
      timeUpdater: null,
      currentTime: null,
    }
  },
  computed: {
    latestDonationsSorted() {
      return this.latestDonations.sort((x, y) => {
        return y.timestamp.toDate() - x.timestamp.toDate()
      })
    },
  },
  created() {
    TimeAgo.addDefaultLocale(TimeAgoLocaleEn)
    this.updateTime()
  },
  beforeDestroy() {
    clearInterval(this.timeUpdater)
  },
  methods: {
    updateTime() {
      this.timeUpdater = setInterval(() => {
        this.currentTime = new Date()
      }, 1000)
    },
    timeAgo(timestamp) {
      const timeAgo = new TimeAgo("en-US")
      return timeAgo.format(timestamp.toDate(), "round")
    },
    senderAddress(address) {
      // if (this.client.viewport.width >= this.truncatedAddressBreakpoint)
      //   return address
      // else return this.truncateAddress(address)
      return this.truncateAddress(address)
    },
  },
}
</script>

<style lang="less" scoped>
.latest {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // border-radius: 8px;

  // margin-top: 10px;

  // @media screen and (max-width: 570px) {
  //   align-items: flex-start;

  //   .donation {
  //     display: flex;
  //     flex-wrap: wrap;
  //     span.time,
  //     span.from {
  //       padding-left: 0 !important;
  //     }
  //   }
  // }
  .wrap {
    // background: var(--color-latest-donations-bg);
    padding: 6px 8px 6px 10px;
    // width: 100%;
    // display: flex;
    display: table;
    flex-direction: column;
    align-items: flex-end;

    .donation {
      // padding: 2px 0 2px 0;
      padding: 4px;
      flex-grow: 1;
      flex-basis: 0;
      margin-bottom: 4px;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: var(--color-latest-donations-bg);

      .time-amount,
      .from-address {
        display: flex;
        flex-direction: row;
      }
      span.time,
      span.from {
        font-size: 0.75rem;
        padding: 0 6px 0 6px;
      }
      span.time {
        white-space: nowrap;
        color: var(--color-latest-donations-time);
      }
      span.amount {
        font-family: monospace;
        color: var(--color-latest-donations-amount);
      }
      span.from {
        color: var(--color-latest-donations-from);
      }
      span.address {
        font-family: monospace;
        color: var(--color-latest-donations-address);
        cursor: copy;

        &:hover {
          color: var(--color-latest-donations-address-hover);
        }
      }
    }

    @media screen and (min-width: 365px) {
      // display: none;
    }
  }
}
</style>
